.billing {
  .card-body {
    padding: 24px 24px;

    .stat-card {
      p {
        display: inline;
        vertical-align: middle;

        @media screen and (min-width: 992px) and (max-width: 1300px) {
          display: block;
        }
      }
    }
  }

  .request-payout-button {
    height: 117px;
    font-size: 16px;

    @media screen and (min-width: 992px) and (max-width: 1300px) {
      height: 132px;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}
