.stat-card {
  display: flex;
  margin-top: 40px;
  margin-bottom: 10px;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .dashboard__stat-info {
    margin: auto 0 auto 30px;

    p {
      color: $color-additional;
    }
  }

  .dashboard__stat-number {
    margin-top: 10px;
  }

  .dashboard__stat-chart {
    position: relative;
    text-align: center;
  }

  .dashboard__stat-label {
    position: absolute;
    font-size: 18px;
    line-height: 20px;
    top: calc(50% - 10px);
    width: 100%;
    margin: 0;
    animation: label 1.5s ease-in;
    color: #dddddd;
  }

  &.dashboard__stat--budget {
    text-align: center;
    flex-wrap: wrap;

    .dashboard__stat-chart {
      margin: auto;
    }

    .dashboard__stat-label {
      font-size: 36px;
    }

    .dashboard__stat-main {
      display: flex;
      flex-direction: column;
      width: 100%;

      hr {
        margin-bottom: 30px;
        margin-top: 40px;
      }
    }

    .dashboard__stat-main-title {
      color: $color-additional;
    }

    .dashboard__stat-main-number {
      display: flex;
      flex-direction: column;
      font-size: 48px;
      line-height: 34px;
      margin-top: 15px;
      white-space: nowrap;

      &.current-balance {
        color: $color-red;
      }

      &.total-balance {
        color: $color-blue;
      }

      &.pending{
        color: $color-yellow;
      }

      &.paid {
        color: $color-success;
      }
    }

    .dashboard__stat-data {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 10px;

      p {
        margin: 0;
      }
    }

    .dashboard__stat-data-number {
      font-size: 18px;
      line-height: 34px;
      font-weight: 500;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1439px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;

    .dashboard__stat-info {
      margin: 0;
    }

    .dashboard__stat-chart {
      margin-bottom: 15px;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1539px) {

    .dashboard__stat-chart {

      svg, div {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
}
