.map-popup {
  &__title {
    color: white !important;
    border-bottom: 1px solid white;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 3px;
  }

  &__text {
    color: white !important;
    margin-top: 2px !important;
    line-height: 1.2;
  }
}
