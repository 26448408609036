.pricing {

  .pricing__select__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 40px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .pricing__select__picker {
    align-self: flex-start;
    margin-bottom: 10px;
  }

  .country__select__result {
    font-size: 16px;

    p {
      margin-top: 3px;
    }
  }
}
