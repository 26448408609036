.invoice-modal {
  width: auto;
  max-width: 80%;
  margin: 1.75rem auto;

  @media screen and (max-width: 576px) {
    max-width: 100%;
    margin: 1.75rem 1.25rem;
  }

  .modal-content {
    @media screen and (max-width: 768px) {
      padding: 30px 10px;
    }
  }
}
