.code-highlight {
  position: relative;

  .clipboard-icon {
    border: none;
    background: none;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 10px;
    color: white;
  }
}
