.statistics {
  .form__form-group {
    width: auto;
    padding: 0 10px;

    @media screen and (max-width: 1225px) {
      width: 100%;
    }
  }

  .btn {
    width: auto;
    height: 32px;
    margin: 24px 10px 0;
    border-radius: 0;
    line-height: 10px;

    @media screen and (max-width: 1225px) {
      width: 100%;
    }
  }
}
