.sdk {
  .sdk-instructions {
    .manual-title {
      margin-top: 30px;
    }

    .instructions {
      margin-top: 20px;

      li {
        margin-top: 20px;
      }

      code {
        margin-top: 20px;
      }
    }
  }

  .onboarding-container {
    margin-top: 10%;
    .onboarding {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        color: black;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .description {
        font-size: 16px;
      }

      .instructions {
        width: 100%;
        max-width: 600px;
        display: flex;
        justify-content: space-between;
        text-align: center;

        .step {
          width: 120px;
          margin: 50px 0;
          img {
            height: 150px;
          }
  
          p {
            font-size: 16px;
          }
        }
      }

      button {
        width: 200px;
      }
    }
  }
}