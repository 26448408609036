.tos-modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: none;
  width: 100%;

  .modal-content {
    height: 80%;
    width: 80%;

    .modal__body {
      height: 80%;

      > div {
        height: 100%;
      }

      .tos-text-block {
        height: 100%;
        overflow: scroll;
        padding: 10px;
        border: 1px solid #e5e7ea;
      }
    }
  }

}
