.tos-container {
  > * {
    color: #646777;
  }

  p {
    margin: 0;
    padding-bottom: 5px;
  }

  > div {
    > ol {
      li {
        font-size: 20px;
        font-weight: bold;
        margin: 15px 0 5px 0;

        p {
          font-size: 14px;
          font-weight: normal;
        }

        ol {
          li {
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            padding-bottom: 5px;
          }
        }
      }
    }
  }

  h1 {
    text-align: center;
    margin: 35px 0;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }
}
