.user-details-modal {
  .card {
    padding-bottom: 0;

    .tabs {
      .tab-content {
        padding: 0;

        .tab-pane {
          .btn-toolbar {
            button {
              height: 34px;
              line-height: 10px;
            }
          }
        }
      }
    }
  }
}
