.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &.account--photo {
    background: url(../../app/img/404/bg_404.png) no-repeat center;
    background-size: cover;

    .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #ffffff;
    }

    .form__form-group-icon, .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon, input:not(:last-child) {
      border-right: none;
    }

    .form__form-group-button, input {
      border-left: none;
    }

    .form__form-group-button.active {
      border-color: #eff1f5;

      svg {
        fill: $color-accent;
      }
    }

    input {
      color: #ffffff;

      &:focus {
        border-color: #eff1f5;
      }
    }

    p {
      color: #ffffff;
    }

    .account__title {
      color: #ffffff;
    }
  }
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px 100px 0 100px;

    img {
      width: 170px;
    }

    a {
      color: $color-gray;
      font-size: 16px;

      svg {
        margin-top: -3px;
      }
    }

    @media screen and (max-width: 570px) {
      padding: 20px 10px;

      img {
        width: 110px;
      }
    }
  }
}

.account__wrapper {
  margin: auto;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  height: 530px;

  #show-bg {
    background-image: url(../../app/img/login/bg.png);
    background-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 450px;
    padding: 60px 50px;

    .info-text {
      z-index: 1;
      line-height: 1.3;
      p {
        font-size: 30px;
        color: #bdacff;
  
        span {
          color: #ffffff;
        }
      }
    }

    .info-img-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      z-index: 1;

      .info-img {
        img {
          width: 40px;
        }
                
        .shady-img {
          width: 35px;
        }

        p {
          margin-top: 20px;
          font-size: 13px;
          width: 70px;
          color: #ffffff;
        }
      }

    }

    @media screen and (max-width: 930px) {
      display: none;
    }
  }

  #mobile-bg {
    background-color: #481afe;
    font-size: 18px;
    padding: 30px;
    max-width: 450px;
    
    .info-text {
      z-index: 1;
      position: relative;
      p {
        color: #bdacff;
  
        span {
          color: #ffffff;
        }
      }
    }

    @media screen and (min-width: 930px) {
      display: none;
    }
  }

  .overlay {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.7;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    }
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.account__card {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  background-color: #ffffff;
  padding: 50px 60px;
  max-width: 450px;
  width: 100%;

  h3 {
    color: black;
    font-weight: 500;
    margin-top: 40px;
  }

  .register-form {
    h3 {
      margin-bottom: 70px;

      @media screen and (max-width: 930px) {
        margin-bottom: 20px;
      }
    }

    .account__btn {
      margin-top: 50px;
      margin-left: 10px;
    }
  }

  .completion-form {
    h3 {
      margin-top: 0;
      margin-bottom: 30px;
    }

    .welcome-back {
      margin-bottom: 0;

      span {
        font-weight: 600;
      }
    }

    .account__btn {
      margin-top: 30px;
      margin-left: 10px;
    }
  }

  .confirmation-info {
    img {
      width: 50px;
      margin-top: 50px;
    }

    .email {
      color: $color-accent;
    }

    .marked {
      font-weight: 600;
    }

    .account__btn {
      margin-bottom: 0;
      margin-top: 100px;
    }
  }
}

.account__profile {
  text-align: center;
}

.account__btns {
  display: flex;
  width: calc(100% + 10px);
  margin: -10px 0 -20px -10px;

  a {
    margin: 10px 0 20px 10px;
    white-space: nowrap;
  }
}

.account__btn {
  width: 100%;
  margin-right: 0;
}

.account__avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.account__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  margin-top: 5px;
}

.account__sub {
  margin-top: 0;
  margin-bottom: 10px;
  color: $color-additional;
  font-size: 11px;
  line-height: 15px;
}

.account__forgot-password {
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -18px;
  right: 0;

  a {
    color: $color-blue;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

.account__or {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  position: relative;

  &:before, &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #dddddd;
    position: absolute;
    top: 10px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.account__social {
  text-align: center;
}

.account__social-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  padding: 9px;
  margin-right: 10px;
  transition: all 0.3s;
  border: none;

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: #ffffff;
    height: 20px;
    width: 20px;
  }

  &.account__social-btn--facebook {
    background: #4766a4;

    &:hover {
      background: darken(#4766a4, 10%);
    }
  }

  &.account__social-btn--google {
    background: #c74d4d;

    &:hover {
      background: darken(#c74d4d, 10%);
    }
  }
}

.account__head {
  margin-bottom: 30px;
  text-align: center;

  img {
    width: 75%;
  }
}

.account__logo {
  font-weight: 700;
}

.account__logo-accent {
  color: $color-blue;
}

.account__have-account {
  text-align: center;
  margin-top: 20px;

  a {
    color: $color-blue;
    transition: all 0.3s;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 930px) {

  .account__card {
    padding: 10px 30px 35px 30px;
  }
}

@media screen and (max-width: 425px) {

  .account__btns {
    flex-wrap: wrap;
  }
}
