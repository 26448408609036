.register {
  .checkbox-btn {
    &:hover {
      .checkbox-btn__checkbox-custom {
        @include themify($themes) {
          border: 1px solid themed('colorIcon');
        }
      }

      .checkbox-btn__label {
        @include themify($themes) {
          color: themed('colorText');
        }
        cursor: default;
      }
    }
  }
}
