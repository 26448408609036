.sdk-instructions {
  .instructions-container {
    padding: 40px 60px;
  }

  .sdk-navigation {
    display: flex;
    flex-direction: column;

    .navigation-button {
      cursor: pointer;
      border: none;
      background: none;
      display: flex;
      align-items: center;
      margin-bottom: 28px;

      img {
        width: 21px;
      }

      p {
        font-size: 19px;
        font-weight: 500;
        padding-top: 5px;
        margin-top: 0;
        margin-left: 20px;
      }
    }

    .active-nav-button {
      img {
        filter: $color-accent;
      }

      p {
        color: $color-accent;
      }
    }
  }

  .list {
    margin-left: 5px;
  }

  .title {
    color: #646777;
    font-size: 24px;
    line-height: 28px;
    margin-top: 20px;
  }

  .note {
    font-size: 17px;
    line-height: 20px;
    margin-top: 20px;
    color: #999999;
  }

  .point {
    font-size: 19px;
    color: #646777;
    line-height: 28px;
    margin-top: 20px;
  }

  .subpoint {
    font-size: 17px;
    margin-top: 7px;
    color: #646777;
  }

  .clipboard {
    position: 'absolute';
    top: 0;
    left: 0;
  }

  .inline-code {
    background: #f0f2f6;
    border-radius: 5px;
    padding: 2px;
    margin: 0 2px;
    color: #5b5e6b;
  }

  pre {
    margin-top: 20px;
  }

  @media screen and (max-width: 1150px) {
    .page-container {
      flex-direction: column;

      .col-md-10 {
        max-width: 100%;
      }

      .sdk-navigation {
        flex-direction: row;
      }
    }
  }
}
