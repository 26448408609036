.invoice {
  .card {
    padding-bottom: 0;

    .card-body {
      padding: 10px;

      .card__title {
        margin-bottom: 15px;
      }

      .info-block {
        ul {
          list-style-type: none;
        }

        @media screen and (max-width: 768px) {
          margin-top: 15px;
        }
      }
    }
  }

  .total-payout {
    float: right;
  }
}
